<div class="main-light-theme">
<mat-toolbar class="mat-elevation-z6"
             color="primary">
    <button mat-button
            routerLink="">
        АМБАР
    </button>
</mat-toolbar>
<div class="mdc-layout-grid">
<div class="mdc-layout-grid__inner pt-1">
<mat-card class="mdc-layout-grid__cell--span-12">
<mat-card-title>
    QUICK VIEW
</mat-card-title>
<mat-card-subtitle>
    {{ datum | date: 'dd.MM.yyyy HH:mm:ss' }}
</mat-card-subtitle>
<mat-card-content *ngIf="izvestaj">
<table [dataSource]="dataSource"
       mat-table>
<ng-container matColumnDef="prodavnica">
    <th *matHeaderCellDef
        mat-header-cell>
        Продавница
    </th>
    <td *matCellDef="let element"
        mat-cell>
        {{ element.prodavnica }}
    </td>
    <td *matFooterCellDef
        class="text-right"
        mat-footer-cell>
        <b>
            Вкупно:
        </b>
    </td>
</ng-container>
<ng-container matColumnDef="vkupenPromet">
    <th *matHeaderCellDef
        class="text-right"
        mat-header-cell>
        Вкупен промет
    </th>
    <td *matCellDef="let element"
        class="text-right"
        mat-cell>
        {{ element.vkupenPromet | number: '1.2-2' }}
    </td>
    <td *matFooterCellDef
        class="text-right"
        mat-footer-cell>
        <b>
            {{ izvestaj.vkupenPromet | number: '1.2-2' }}
        </b>
    </td>
</ng-container>
<ng-container matColumnDef="voGotovo">
    <th *matHeaderCellDef
        class="text-right"
        mat-header-cell>
        Во готово
    </th>
    <td *matCellDef="let element"
        class="text-right"
        mat-cell>
        {{ element.voGotovo | number: '1.2-2' }}
    </td>
    <td *matFooterCellDef
        class="text-right"
        mat-footer-cell>
        <b>
            {{ izvestaj.voGotovo | number: '1.2-2' }}
        </b>
    </td>
</ng-container>
<ng-container matColumnDef="kartica">
    <th *matHeaderCellDef
        class="text-right"
        mat-header-cell>
        Картица
    </th>
    <td *matCellDef="let element"
        class="text-right"
        mat-cell>
        {{ element.kartica | number: '1.2-2' }}
    </td>
    <td *matFooterCellDef
        class="text-right"
        mat-footer-cell>
        <b>
            {{ izvestaj.kartica | number: '1.2-2' }}
        </b>
    </td>
</ng-container>
<ng-container matColumnDef="diners">
    <th *matHeaderCellDef
        class="text-right"
        mat-header-cell>
        Diners
    </th>
    <td *matCellDef="let element"
        class="text-right"
        mat-cell>
        {{ element.diners | number: '1.2-2' }}
    </td>
    <td *matFooterCellDef
        class="text-right"
        mat-footer-cell>
        <b>
            {{ izvestaj.diners | number: '1.2-2' }}
        </b>
    </td>
</ng-container>
<ng-container matColumnDef="fakturi">
    <th *matHeaderCellDef
        class="text-right"
        mat-header-cell>
        Фактура
    </th>
    <td *matCellDef="let element"
        class="text-right"
        mat-cell>
        {{ element.fakturi | number: '1.2-2' }}
    </td>
    <td *matFooterCellDef
        class="text-right"
        mat-footer-cell>
        <b>
            {{ izvestaj.fakturi | number: '1.2-2' }}
        </b>
    </td>
</ng-container>
<ng-container matColumnDef="giftKarti">
    <th *matHeaderCellDef
        class="text-right"
        mat-header-cell>
        Gift Cards
    </th>
    <td *matCellDef="let element"
        class="text-right"
        mat-cell>
        {{ element.giftKarti | number: '1.2-2' }}
    </td>
    <td *matFooterCellDef
        class="text-right"
        mat-footer-cell>
        <b>
            {{ izvestaj.giftKarti | number: '1.2-2' }}
        </b>
    </td>
</ng-container>
<ng-container matColumnDef="kesh">
    <th *matHeaderCellDef
        class="text-right"
        mat-header-cell>
        Кеш
    </th>
    <td *matCellDef="let element"
        class="text-right"
        mat-cell>
        {{ element.kesh | number: '1.2-2' }}

    </td>
    <td *matFooterCellDef
        class="text-right"
        mat-footer-cell>
        <b>
            {{ izvestaj.kesh | number: '1.2-2' }}
        </b>
    </td>
</ng-container>
<ng-container matColumnDef="pariZaUplata">
    <th *matHeaderCellDef
        class="text-right"
        mat-header-cell>
        Пари за уплата
    </th>
    <td *matCellDef="let element"
        class="text-right"
        mat-cell>
        {{ element.pariZaUplata | number: '1.2-2' }}

    </td>
    <td *matFooterCellDef
        class="text-right"
        mat-footer-cell>
        <b>
            {{ izvestaj.pariZaUplata | number: '1.2-2' }}
        </b>
    </td>
</ng-container>
<ng-container matColumnDef="primeniParcinja">
    <th *matHeaderCellDef
        class="text-right"
        mat-header-cell>
        Примени парчиња
    </th>
    <td *matCellDef="let element"
        class="text-right"
        mat-cell>
        {{ element.primeniParcinja | number: '1.0-0' }}
    </td>
    <td *matFooterCellDef
        class="text-right"
        mat-footer-cell>
        <b>
            {{ izvestaj.primeniParcinja | number: '1.0-0' }}
        </b>
    </td>
</ng-container>
<ng-container matColumnDef="prodadeniParcinja">
    <th *matHeaderCellDef
        class="text-right"
        mat-header-cell>
        Продадени парчиња
    </th>
    <td *matCellDef="let element"
        class="text-right"
        mat-cell>
        {{ element.prodadeniParcinja | number: '1.0-0' }}
    </td>
    <td *matFooterCellDef
        class="text-right"
        mat-footer-cell>
        <b>
            {{ izvestaj.prodadeniParcinja | number: '1.0-0' }}
        </b>
    </td>
</ng-container>
<ng-container matColumnDef="zaliha">
    <th *matHeaderCellDef
        class="text-right"
        mat-header-cell>
        Залиха
    </th>
    <td *matCellDef="let element"
        class="text-right"
        mat-cell>
        {{ element.zaliha | number: '1.0-0' }}
    </td>
    <td *matFooterCellDef
        class="text-right"
        mat-footer-cell>
        <b>
            {{ izvestaj.zaliha | number: '1.0-0' }}
        </b>
    </td>
</ng-container>
<tr *matHeaderRowDef="dataColumns"
    mat-header-row>
</tr>
<tr *matRowDef="let row; columns: dataColumns;"
    mat-row>
</tr>
<tr *matFooterRowDef="dataColumns"
    mat-footer-row>
</tr>
</table>
</mat-card-content>
</mat-card>
</div>
</div>
</div>
