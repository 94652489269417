import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MessageDialogService } from '../../core/message-dialog.service';

@Component({
    templateUrl: './log-in-dialog.component.html',
})
export class LogInDialogComponent {

    forma: FormGroup;

    constructor(public formBuilder: FormBuilder,
        public router: Router,
        public messageDialogService: MessageDialogService,
        public dialogRef: MatDialogRef<LogInDialogComponent>) {
        this.createForm();
    }

    createForm() {
        this.forma = this.formBuilder.group({
            password: new FormControl('', { validators: [Validators.required] })
        });
    }

    login() {
        if (this.forma.value.password === '@mb@r2023!*') {
            localStorage.setItem('login', `${(new Date()).getTime() + 3600000}`);
            this.dialogRef.close(true);
        } else {
            this.dialogRef.close(false);
        }
    }

}
