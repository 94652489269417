import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IQuickViewIzvestaj } from '../models/quick-view';

@Injectable()
export class IzvestaiService {

    constructor(private http: HttpClient) {}

    getQuickView(): Observable<IQuickViewIzvestaj> {
        return this.http.get<IQuickViewIzvestaj>(`/IzvestaiApi/QuickView/Get`);
    }

}
