import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { OverlayContainer } from '@angular/cdk/overlay';
import { IzvestaiService } from './core/izvestai.service';
import { MessageDialogService } from './core/message-dialog.service';
import { LogInDialogComponent } from './dialogs/log-in-dialog/log-in-dialog.component';
import { IQuickViewIzvestaj } from './models/quick-view';
import { IQuickViewDetali } from './models/quick-view';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    izvestaj: IQuickViewIzvestaj;
    loggedIn = false;
    datum = new Date();

    constructor(public messageDialogService: MessageDialogService,
        public overlayContainer: OverlayContainer,
        public dialogService: MatDialog,
        public izvestaiService: IzvestaiService) {
        this.setup();
        this.overlayContainer.getContainerElement().classList.add('main-light-theme');
    }

    dataSource = new MatTableDataSource([] as IQuickViewDetali[]);
    dataColumns = [
        'prodavnica', 'vkupenPromet', 'voGotovo', 'kartica', 'diners', 'giftKarti', 'kesh', 'pariZaUplata', 'fakturi',
        'primeniParcinja', 'prodadeniParcinja', 'zaliha'
    ];

    setup() {
        this.isLoggedIn();
        if (this.loggedIn) {
            this.messageDialogService.showSpinner();
            this.izvestaiService.getQuickView().subscribe((data) => {
                this.datum = new Date();
                this.izvestaj = data;
                this.dataSource.data = this.izvestaj.glavniDetali;
                this.messageDialogService.hideSpinner();
            });
        } else {
            this.login();
        }
    }

    isLoggedIn() {
        this.loggedIn = false;
        const time = localStorage.getItem('login');
        if (!time) {
            this.loggedIn = false;
        }
        const now = new Date();
        if (now.getTime() > Number(time)) {
            this.loggedIn = false;
            localStorage.removeItem('login');
        } else {
            this.loggedIn = true;
        }
    }

    login() {
        this.dialogService.open(LogInDialogComponent, { minWidth: '50vw' }).afterClosed()
            .subscribe((result: string) => {
                if (result) {
                    this.setup();
                } else {
                    this.login();
                }
            });
    }

}
