<div matDialogTitle>
    Најави се
</div>
<form (keydown.enter)="$event.preventDefault()"
      [formGroup]="forma"
      novalidate>
    <div mat-dialog-content>
        <div class="mdc-layout-grid">
            <div class="mdc-layout-grid__inner">
                <mat-form-field class="mdc-layout-grid__cell--span-12">
                    <input formControlName="password"
                           matInput
                           placeholder="Лозинка"
                           type="password">
                    <mat-error *ngIf="forma.get('password').hasError('required')">
                        Лозинката е задолжителна
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div align="end"
         mat-dialog-actions>
        <button (click)="login();"
                (keydown.enter)="login();"
                [disabled]="forma.invalid"
                color="primary"
                mat-raised-button>
            Најави се
        </button>
    </div>
</form>
